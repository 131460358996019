<template>
  <router-link
    class="accommodation-card"
    :to="{
      name: 'foravila-accommodation-view',
      params: { slug: accommodation.slug },
    }"
  >
    <b-card
      :img-src="accommodationPicture"
      img-top
      no-body
      class="hover-animate"
    >
      <b-card-body>
        <small v-if="accommodationName">
          {{ accommodationSubtitle }}
        </small>
        <b-card-title
          v-if="accommodationName"
          class="mb-0"
          :class="{ 'mt-3': accommodationSubtitle }"
        >
          {{ accommodationName }}
        </b-card-title>
        <small
          v-if="accommodationCity"
          class="text-uppercase letter-spacing-foravila font-weight-light"
        >
          {{ accommodationCity }}
        </small>
        <div class="d-block d-md-none text-primary mt-3">
          {{ $t("See details") }}
        </div>
      </b-card-body>
    </b-card>
  </router-link>
</template>

<script>
import { BCard, BCardBody, BCardTitle } from "bootstrap-vue";
import { ACCOMMODATION_PLACEHOLDER_SM } from "@consts";
import { getBathroomsText, getBedroomsText } from "@methods";

export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
  },
  props: {
    accommodation: {
      type: Object,
      required: true,
    },
  },
  computed: {
    accommodationName() {
      return this.accommodation?.name || null;
    },
    accommodationPicture() {
      return (
        this.accommodation?.coverPicture?.thumbnailUrl ||
        ACCOMMODATION_PLACEHOLDER_SM
      );
    },
    accommodationSubtitle() {
      const subtitle = [];

      if (this.accommodation.bedroomsNumber) {
        subtitle.push(
          getBedroomsText(this.accommodation.bedroomsNumber, this.$i18n.locale)
        );
      }
      if (this.accommodation.bathroomsNumber) {
        subtitle.push(
          getBathroomsText(
            this.accommodation.bathroomsNumber,
            this.$i18n.locale
          )
        );
      }
      return subtitle.length ? subtitle.join(" · ") : null;
    },
    accommodationCity() {
      return this.accommodation?.location?.city || null;
    },
  },
};
</script>

<style lang="scss">
.accommodation-card,
.accommodation-card:hover {
  text-decoration: none;
  color: inherit;
}
.card-img-top {
  border-radius: 0 !important;
}
.hover-animate {
  transition: all 0.2s ease-in-out;
  a {
    text-decoration: none;
    color: inherit;
  }
}
.hover-animate:hover {
  -webkit-transform: translate3d(0, -2px, 5px);
  transform: translate3d(0, -2px, 5px);
  cursor: pointer;
}
</style>
