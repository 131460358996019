<template>
  <b-overlay :show="loading" :opacity="1">
    <div id="accommodations-list" class="mt-4">
      <b-row>
        <b-col
          v-for="accommodation in currentAccommodations"
          :key="`accommodation-${accommodation.uuid}`"
          cols="12"
          sm="6"
          lg="3"
        >
          <accommodation-card :accommodation="accommodation" />
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
import { BOverlay, BRow, BCol } from "bootstrap-vue";
import AccommodationCard from "@/views/accommodations/list/components/AccommodationCard.vue";

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    AccommodationCard,
  },
  computed: {
    loading() {
      return this.$store.getters["owner/loading"];
    },
    owner() {
      return this.$store.getters["owner/owner"];
    },
    currentAccommodations() {
      return this.$store.getters["owner/currentAccommodations"];
    },
  },
  created() {
    if (!this.loading && !this.owner) this.fetchOwner();
  },
  methods: {
    fetchOwner() {
      const userData = JSON.parse(
        localStorage.getItem("foravilaOwnersUserData")
      );
      const ownerUuid = userData?.owner?.uuid || null;
      this.$store.dispatch("owner/fetchOwner", ownerUuid);
      // TODO: pensar que ha de passar si es fetchOwner ens dona error
    },
  },
};
</script>

<style lang="scss">
#accommodations-list {
  min-height: 100px;
}
</style>
